<template>
  <div class="intro-screen bg-primary relative overflow-hidden">
    <div class="intro-img-background img-btc">
      <img src="@/assets/images/BTC3.png" />
    </div>
    <div class="intro-img-background img-eth">
      <img src="@/assets/images/ETH3.png" />
    </div>
    <div class="intro-img-background img-other">
      <img src="@/assets/images/other3.png" />
    </div>
    <div
      class="
        absolute
        left-0
        top-0
        h-full
        w-full
        overflow-x-hidden overflow-y-auto
      "
    >
      <div class="main-content absolute w-full">
        <div class="absolute left-0 top-0 h-full w-full overflow-hidden">
          <div class="blur absolute"></div>
        </div>
        <div class="intro-content text-center z-10">
          <img src="@/assets/images/app_logo.png" class="intro-logo" />
          <h1 class="mt-4 mb-8 mobileM:mb-10">Chào mừng đến với GOMI</h1>
          <div class="px-6 text-primary font-medium mb-8 mobileM:mb-14">
            <p class="text-left text-2xl">
              <span class="text-4xl">“</span>Đầu tư dễ dàng
            </p>
            <p class="text-right text-2xl">
              Sinh lời hấp dẫn<span class="text-4xl">“</span>
            </p>
          </div>
          <div class="intro-content-button">
            <p class="mb-2 text-sm">
              <i>Sản phẩm được phát triển bởi Interloan</i>
            </p>
            <base-button
              class="w-full bg-tertiary text-white"
              @click="handleClickNo"
            >
              Tham gia ngay
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ConstRouter from '@/constants/constRouter';
import BaseButton from '@/components/Base/BaseButton.vue';
import * as Analytics from '@/services/analytics';
// import { encrypt } from '@/helpers/payme';
// import { payMe } from '@/services/payme';
// import configs from '@/services/payme';
export default defineComponent({
  name: 'IntroSlide',
  components: { BaseButton },
  data() {
    return {
      phoneNumber: '0869932748',
      secretKey: '8dd16a54ddd368160701621453e0eaa1',
    };
  },
  methods: {
    handleClickNo() {
      Analytics.eventK2A(
        'REGISTER',
        'DONE_INTRO',
        'Bấm bắt đầu từ trang giới thiệu',
      );
      this.$router.push({
        name: ConstRouter.REGISTER_PHONE.name,
      });
    },

    // handleClickNo() {
    //   try {
    //     const connectToken = encrypt(
    //       JSON.stringify({
    //         userId: 'ff33',
    //         timestamp: new Date().toISOString(),
    //         phone: this.phoneNumber,
    //         kycInfo: {
    //           fullname: 'Võ Hoàng Nhật',
    //           gender: 'MALE',
    //           birthday: new Date().toISOString(),
    //           address: '412 Nguyen Thi Minh Khai',
    //           identifyType: 'CMND',
    //           identifyNumber: '221467360',
    //           issuedAt: new Date().toISOString(),
    //           placeOfIssue: 'Hồ Chí Minh',
    //         },
    //       }),
    //       this.secretKey,
    //     );
    //     console.log(configs);
    //     const configsLogin = {
    //       ...configs,
    //       connectToken,
    //       phone: this.phoneNumber,
    //     };
    //     payMe.login(
    //       configsLogin,
    //       (respone) => console.log('respone login', respone),
    //       (error) => {
    //         console.log('error login', error);
    //       },
    //     );
    //   } catch (error) {
    //     console.log('error', error);
    //   }
    //   // router.push({
    //   //   name: ConstRouter.REGISTER_PHONE.name,
    //   // });
    // },
  },
});
</script>
<style lang="scss" scoped>
.intro-screen {
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  height: 100vh;
  .main-content {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: #f3f5f6;
    top: 282px;
    min-height: calc(100% - 282px);
    height: max-content;
  }
  .intro-img-background {
    position: absolute;
    &.img-btc {
      width: 75px;
      top: 45px;
      left: 30px;
    }
    &.img-eth {
      width: 160px;
      top: 41px;
      right: -50px;
    }
    &.img-other {
      width: 172px;
      left: -65px;
      top: 170px;
    }
  }
  .intro-content {
    position: relative;
    padding: 45px 25px;
    .intro-logo {
      position: absolute;
      width: 232px;
      top: -180px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .blur {
    width: 249px;
    height: 249px;
    background: #def5e9;
    border-radius: 100%;
    right: -120px;
    bottom: -75px;
    z-index: 1;
    filter: blur(70px);
    -webkit-backdrop-filter: blur(70px);
    backdrop-filter: blur(70px);
  }
}

@media only screen and (min-width: 426px) {
  .intro-screen {
    border-radius: 28px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    min-height: 580px;
  }
}
</style>
